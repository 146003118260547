import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

const MAX_VOLUME = 20;

@customElement('volume-dial')
export class VolumeDial extends LitElement {
    @property({ type: Number }) volume = 5;

    static styles = css`
    :host {
        --size: 160px;
        --width: 160px;
        --height: 200px;
        --dot-radius: 48px;
        --num-dots: 20;
        --dot-angle-min: -225deg;
        --dot-angle-step: calc(270deg / var(--num-dots))
    }

    @media(prefers-color-scheme: light) {
      :host {
        --bg-color: #ffffff;
        --fg-color: #101010;
      }
    }

    @media(prefers-color-scheme: dark) {
      :host {
        --bg-color: '#101010';
        --fg-color: '#ffffff';
      }
    }

    #root {
        position: relative;
        width: var(--width);
        height: var(--height);
        background: var(--bg-color);
        overflow: hidden;
    }

    #knob {
        position: absolute;
        left: 50%;
        top: 50%;
    }

    #min {
        position: absolute;
        left: 35px;
        top: 125px;
    }

    #down {
        position: absolute;
        left: 35px;
        top: 145px;
    }

    #max {
        position: absolute;
        left: 95px;
        top: 125px;
    }

    #up {
        position: absolute;
        left: 95px;
        top: 145px;
    }

    .circle-container {
        width: var(--size);
        height: var(--size);
        list-style: none;
        padding: 0;
        margin: 0;
        }

    .circle-container ul {
        position: absolute;
        left: 50%;
        top: 50%;
    }

    .circle-container li {
        position: absolute;
        left: calc(var(--size) / 2.0);
        top: calc(var(--size) / 2.0);
        width: 5px;
        height: 5px;
        background: pink;
        border-radius: 50%;
        text-align: center;
        line-height: 5px;
        color: white;
    }

    .circle-container li[on=true] {
        background: red;
    }

    .circle-container li[on=false] {
        background: gray;
    }

    .circle-container li { transform: rotate(calc(var(--dot-angle-min) + var(--dot-angle-step) * var(--index))) translate(var(--dot-radius)) rotate(calc(-1 * (var(--dot-angle-min) + var(--dot-angle-step) * var(--index)))); }

    .circle-container li:nth-child(1) { --index: 0; }
    .circle-container li:nth-child(2) { --index: 1; }
    .circle-container li:nth-child(3) { --index: 2; }
    .circle-container li:nth-child(4) { --index: 3; }
    .circle-container li:nth-child(5) { --index: 4; }
    .circle-container li:nth-child(6) { --index: 5; }
    .circle-container li:nth-child(7) { --index: 6; }
    .circle-container li:nth-child(8) { --index: 7; }
    .circle-container li:nth-child(9) { --index: 8; }
    .circle-container li:nth-child(10) { --index: 9; }
    .circle-container li:nth-child(11) { --index: 10; }
    .circle-container li:nth-child(12) { --index: 11; }
    .circle-container li:nth-child(13) { --index: 12; }
    .circle-container li:nth-child(14) { --index: 13; }
    .circle-container li:nth-child(15) { --index: 14; }
    .circle-container li:nth-child(16) { --index: 15; }
    .circle-container li:nth-child(17) { --index: 16; }
    .circle-container li:nth-child(18) { --index: 17; }
    .circle-container li:nth-child(19) { --index: 18; }
    .circle-container li:nth-child(20) { --index: 19; }
    .circle-container li:nth-child(21) { --index: 20; }
  `;

    render() {
        return html`
      <div id="root">
        <ul class="circle-container">
            <li on="${this.volume >= 0}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 1}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 2}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 3}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 4}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 5}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 6}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 7}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 8}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 9}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 10}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 11}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 12}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 13}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 14}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 15}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 16}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 17}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 18}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 19}" @click="${this.volumeDotClicked}"></li>
            <li on="${this.volume >= 20}" @click="${this.volumeDotClicked}"></li>
        </ul>
        <span id="min">MIN</span>
        <span id="max">MAX</span>
        <img id="knob" src="" alt="Volume dial" />
        <button id="down" type="button" @click="${this.volumeDown}">-</button>
        <button id="up" type="button" @click="${this.volumeUp}">+</button>
      </div>
    `;
    }

    private volumeDotClicked(e: Event) {
        const child = e.currentTarget as HTMLInputElement;
        if (child && child.parentElement) {
            const newVolumeLevel = Array.prototype.indexOf.call(child.parentElement.children, child);
            this.volume = newVolumeLevel;
        }
    }

    private volumeDown(_e: Event) {
        if (this.volume > 0) {
            this.volume--;
        }
    }

    private volumeUp(_e: Event) {
        if (this.volume < MAX_VOLUME) {
            this.volume++;
        }
    }
}
