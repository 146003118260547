import { css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { styles } from '../styles/shared-styles';
import { MobxLitElement } from '@adobe/lit-mobx';
import { playbackStore } from '../stores/playbackStore';

import { previousIcon } from '../icons/previousIcon';
import { nextIcon } from '../icons/nextIcon';
import { repeatIcon } from '../icons/repeatIcon';
import { shuffleIcon } from '../icons/shuffleIcon';
import { soundIcon } from '../icons/soundIcon';


@customElement('play-bar')
export class PlayBar extends MobxLitElement {
    // import { property } from 'lit/decorators.js';
    // @property({ type: Number }) volume = 5;

    static styles = [styles, css`
    :host {
    }

    @media(prefers-color-scheme: light) {
      :host {
      }
    }

    @media(prefers-color-scheme: dark) {
      :host {
      }
    }

    #root {
        display: grid;
        grid-template-columns: 1fr auto auto auto auto auto 1fr;
        grid-template-rows: auto auto auto;
        column-gap: 8px;
        align-items: center;
        border-radius: 5px 5px 0 0;
        width: 100%;
        height: 100%;
        background: var(--bg-color-invert);
        color: var(--fg-color-invert);
    }

    #shuffle {
        grid-row: 1;
        grid-column: 2;
    }

    #previous {
        grid-row: 1;
        grid-column: 3;
    }

    #play-pause {
        padding: 8px;
        margin: 8px;
        grid-row: 1;
        grid-column: 4;
        width: 56px;
        height: 56px;
    }

    #next {
        grid-row: 1;
        grid-column: 5;
    }

    #repeat {
        grid-row: 1;
        grid-column: 6;
    }

    #now-playing {
        grid-row: 1;
        grid-column: 1 / 2;
        width: 100%;
        height: 100%;
        padding-left: 8px;

        display: grid;
        grid-template-rows: 1fr auto auto 1fr;
        grid-template-columns: auto 1fr;
        column-gap: 8px;
        row-gap: 2px;
    }

    #album {
        grid-row: 2 / 3;
        grid-column: 2 / 3;

        font-size: 14px;
        color: var(--text-primary-invert);
    }

    #artist {
        grid-row: 3 / 4;
        grid-column: 2 / 3;

        font-size: 12px;
        color: var(--text-subtitle-invert);
    }

    #cover-art {
        grid-row: 1 / 5;
        grid-column: 1 / 2;
        width: 64px;
        height: 64px;
        border-radius: 4px;
        overflow: hidden;
        align-self: center;
    }

    #volume {
        grid-row: 1;
        grid-column: 7;
        width: 100%;
        display: flex;
        justify-content: end;

        margin-top: auto;
        margin-bottom: auto;
        vertical-align: baseline;
    }

    #mute {

    }

    #volumeText {
        font-variant-numeric: tabular-nums;
        width: 3ch;
        text-align: end;
        margin-top: auto;
        margin-bottom: auto;
        margin-right: 8px;
    }

    .centered-item {
        width: 40px;
        height: 40px;
        background: var(--bg-color-invert);
        padding: 0;

        color: inherit;
        border: none;
        border-radius: 3px;
        font: inherit;

        outline: inherit;

        button& {
            cursor: pointer;

            &:hover {
                background: #303030;
            }
        }

        & svg {
            width: 24px;
            height: 24px;
            margin: 8px;
            color: var(--fg-color-invert);
        }
    }

    input[type=range] {
        margin: 6px 0;
        background-color: transparent;
        -webkit-appearance: none;
    }

    input[type=range]:focus {
        outline: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
        background: var(--fg-color-invert);
        border: 1px solid var(--bg-color-invert);
        border-radius: 25px;
        width: 100%;
        height: 4px;
        cursor: pointer;
    }

    input[type=range]::-webkit-slider-thumb {
        margin-top: -7px;
        width: 16px;
        height: 16px;
        background: var(--fg-color-invert);
        border: 2px solid var(--bg-color-invert);
        border-radius: 50%;
        cursor: pointer;
        -webkit-appearance: none;
    }

    input[type=range]:focus::-webkit-slider-runnable-track {
        background: var(--fg-color-invert);
    }

    input[type=range]::-moz-range-track {
        background: var(--fg-color-invert);
        border: 1px solid var(--fg-color-invert);
        border-radius: 25px;
        width: 100%;
        height: 4px;
        cursor: pointer;
    }

    input[type=range]::-moz-range-thumb {
        width: 16px;
        height: 16px;
        background: var(--fg-color-invert);
        border: 2px solid var(--bg-color-invert);
        border-radius: 50%;
        cursor: pointer;
    }

    input[type=range]::-ms-track {
        background: transparent;
        border-color: transparent;
        border-width: 6.9px 0;
        color: transparent;
        width: 100%;
        height: 4px;
        cursor: pointer;
    }

    input[type=range]::-ms-fill-lower {
        background: var(--bg-color-invert);
        border: 1px solid var(--bg-color-invert);
        border-radius: 50px;
    }

    input[type=range]::-ms-fill-upper {
        background: var(--bg-color-invert);
        border: 1px solid var(--bg-color-invert);
        border-radius: 50px;
    }

    input[type=range]::-ms-thumb {
        width: 16px;
        height: 16px;
        background: var(--fg-color-invert);
        border: 1.8px solid #00001e;
        border-radius: 1px;
        cursor: pointer;
        margin-top: 0px;
        /*Needed to keep the Edge thumb centered*/
    }

    input[type=range]:focus::-ms-fill-lower {
        background: var(--bg-color-invert);
    }

    input[type=range]:focus::-ms-fill-upper {
        background: var(--bg-color-invert);
    }
  `];

    render() {
        return html`
      <div id="root">
        <button id="shuffle" class="centered-item">
            ${shuffleIcon}
        </button>
        <button id="previous" class="centered-item">
            ${previousIcon}
        </button>

        <play-pause-button id="play-pause"></play-pause-button>

        <button id="next" class="centered-item">
            ${nextIcon}
        </button>
        <button id="repeat" class="centered-item">
            ${repeatIcon}
        </button>

        <div id="now-playing">
            <img id="cover-art" src="${playbackStore.coverArtUri}" />
            <span id="album">${playbackStore.album}</span>
            <span id="artist">${playbackStore.artist}</span>
        </div>

        <div id="volume">
            <div id="mute" class="centered-item">
                ${soundIcon}
            </div>
            <input type="range" min="0" max="1" value="${playbackStore.volume}" step="0.01" @input="${this.onVolumeInputChanged}"></input>
            <span id="volumeText">${(playbackStore.volume * 100).toFixed(0)}</span>
        </div>
      </div>
    `;
    }

    private onVolumeInputChanged(e: Event) {
        const element = e.target as HTMLInputElement;
        const value = parseFloat(element.value);
        playbackStore.setVolume(value);
    }
}
