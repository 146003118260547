import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { styles } from '../styles/shared-styles';

@customElement('app-nav')
export class AppNav extends LitElement {
  // import { property } from 'lit/decorators.js';
  // @property({ type: Number }) volume = 5;

  static styles = [styles, css`
    :host {

    }

    @media(prefers-color-scheme: light) {
      :host {
      }
    }

    @media(prefers-color-scheme: dark) {
      :host {
      }
    }

    #root {
        height: 100%;
        background: var(--bg-color);
    }
  `];

  render() {
    return html`
      <div id="root">

      </div>
    `;
  }
}
