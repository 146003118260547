import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
// import { resolveRouterPath } from '../router';

import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';

import { styles } from '../styles/shared-styles';

@customElement('app-home')
export class AppHome extends LitElement {

  // For more information on using properties and state in lit
  // check out this link https://lit.dev/docs/components/properties/
  @property() message = 'Welcome!';

  static styles = [styles, css`
    main {
      display: grid;
      height: 100%;
      grid-template-columns: min-content minmax(0, 1fr);
      grid-template-rows: 50px minmax(0, 1fr) minmax(0, auto);
      overscroll-behavior: none;
    }
    app-header {
      grid-row: 1 / 2;
      grid-column: 1 / 3;
    }
    app-nav {
      grid-row: 2 / 3;
      grid-column: 1 / 2;

      overflow: auto;
      resize: horizontal;

      min-width: 120px;
      max-width: 40vw;
      width: 300px;
    }
    app-content {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
    play-bar {
      grid-row: 3 / 4;
      grid-column: 1 / 3;
    }
    `];

  async firstUpdated() {
    // this method is a lifecycle even in lit
    // for more info check out the lit docs https://lit.dev/docs/components/lifecycle/
    console.log('This is your home page');
  }

  share() {
    if ((navigator as any).share) {
      (navigator as any).share({
        title: 'PWABuilder pwa-starter',
        text: 'Check out the PWABuilder pwa-starter!',
        url: 'https://github.com/pwa-builder/pwa-starter',
      });
    }
  }

  render() {
    return html`
      <main>
        <app-header></app-header>
        <app-nav></app-nav>
        <app-content></app-content>
        <play-bar></play-bar>
      </main>
    `;
  }
}
