import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
import { styles } from '../styles/shared-styles';

@customElement('app-header')
export class AppHeader extends LitElement {
    // import { property } from 'lit/decorators.js';
    // @property({ type: Number }) volume = 5;

    static styles = [styles, css`
    :host {
    }

    @media(prefers-color-scheme: light) {
      :host {

      }
    }

    @media(prefers-color-scheme: dark) {
      :host {

      }
    }

    #root {
        display: flex;
        width: 100%;
        height: 100%;
        background: var(--bg-color);
    }

    #search-text {
        margin: auto 0 auto 20px;
    }
    #search-button {
        margin: auto 0 auto 4px;
    }
  `];

    render() {
        return html`
      <div id="root">
        <input id="search-text" type="text" placeholder="Search"></input>
        <button id="search-button">🔎</button>
      </div>
    `;
    }
}
