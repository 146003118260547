import { css, html, TemplateResult } from 'lit';
import { MobxLitElement } from '@adobe/lit-mobx';
import { customElement, property } from 'lit/decorators.js';
import { styles } from '../styles/shared-styles';
import { playbackStore } from '../stores/playbackStore';

@customElement('app-content')
export class AppContent extends MobxLitElement {
    @property({ type: Boolean }) isDraggedOver = false;

    private FilePickerKey = "FilePicker"

    static styles = [styles, css`
    :host {

    }

    @media(prefers-color-scheme: light) {
      :host {
      }
    }

    @media(prefers-color-scheme: dark) {
      :host {
      }
    }

    #root {
        width: 100%;
        height: 100%;
        overflow-x: auto;
        background: var(--bg-color);
    }

    // input[type="file"] {
    //     display: none;
    // }
  `];

    render(): TemplateResult {
        console.log(`app-content render ${Date.now()}`);

        return html`
<div id="root">
    <div @onDrop="${this.dropHandler}" @onDragOver="${this.dragOverHandler}" @onDragEnter="${this.dragEnterHandler}" @onDragLeave="${this.dragLeaveHandler}">
        <!--
        <p>Add your music folder to get started</p>
        <input title="file picker" type="file" id="${this.FilePickerKey}" @onChange="${this.onFilePickerChanged}" webkitdirectory></input>
        <button @click="${this.openFilePicker}">browse...</button>

        <h1>Directory File picker:</h1>
        <input title="directory picker" type="file" id="FileUpload" @change="${this.selectFolder}" webkitdirectory mozdirectory msdirectory odirectory directory multiple />
        -->

        <input title="mp3 file picker" type="file" accept=".mp3" @change="${this.onMp3PickerChanged}" />
    </div>
</div>
    `;
    }

    private onMp3PickerChanged(e: Event) {
        var files = (e.target as HTMLInputElement).files;

        if (files && files[0]) {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                const mp3Uri = reader.result as string

                playbackStore.setTrackUri(mp3Uri);
                playbackStore.play();
            });
            reader.readAsDataURL(files[0]);
        }
    }

    private openFilePicker(_e: Event) {
        this.shadowRoot?.getElementById(this.FilePickerKey)!.click();
    }

    private selectFolder(e: Event) {
        var theFiles = (e.target as HTMLInputElement).files;
        if (theFiles) {
            var relativePath = theFiles[0].webkitRelativePath;
            var folder = relativePath.split("/");
            alert(folder[0]);
        }
    }

    private onFilePickerChanged() {
        const file = (document.getElementById(this.FilePickerKey) as HTMLInputElement)?.files;
        if (file) {
            const fileItem = file.item(0);
            if (fileItem) {
                const reader = new FileReader();
                reader.addEventListener('load', () => {
                    console.log(`@@@ TODO: set result in state: ${reader.result as string}`)
                    //setSrc(reader.result as string);
                });
                reader.readAsDataURL(fileItem);
            }
        }
    }

    private dragOverHandler(e: DragEvent) {
        e.preventDefault();
    }

    private dragEnterHandler() {
        this.isDraggedOver = true;
    }

    private dragLeaveHandler() {
        this.isDraggedOver = false;
    }

    private dropHandler(e: DragEvent) {
        // Prevent default behavior (Prevent file from being opened)
        e.preventDefault();

        if (e.dataTransfer?.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (let i = 0; i < e.dataTransfer.items.length; i++) {
                const item = e.dataTransfer.items[i];
                if (item.kind === "file") {
                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                        const fileUri = reader.result as string;
                        console.log(`@@@ TODO: set result in state: ${fileUri}`)

                    });
                    reader.readAsDataURL(item.getAsFile()!);
                    break;
                }
            }
        }
    }
}
