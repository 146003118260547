import { css } from 'lit';

// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const styles = css`
  @media(prefers-color-scheme: light) {
    :host {
      --bg-color: #ffffff;
      --fg-color: #101010;
      --text-primary: #000;
      --text-subtitle: #333;

      --bg-color-invert: var(--fg-color);
      --fg-color-invert: var(--bg-color);
      --text-primary-invert: #fff;
      --text-subtitle-invert: #d0d0d0;
    }
  }

  @media(prefers-color-scheme: dark) {
    :host {
      --bg-color: #101010;
      --fg-color: #ffffff;
      --text-primary: #fff;
      --text-subtitle: #d0d0d0;

      --bg-color-invert: var(--fg-color);
      --fg-color-invert: var(--bg-color);
      --text-primary-invert: #000;
      --text-subtitle-invert: #333;
    }
  }

  input,
  textarea,
  select,
  button {
    font-family: Urbanist, Tahoma, Verdana, sans-serif;
  }
`;