// @ts-nocheck

// Copied and modified from jdataview npm package to address
// https://github.com/jDataView/jDataView/issues/87

// Example usage to read ID3 tag
// ---
// var reader = new FileReader();
// reader.onload = function (e) {
//     var dv = new jDataView(this.result);

//     // "TAG" starts at byte -128 from EOF.
//     // See http://en.wikipedia.org/wiki/ID3
//     if (dv.getString(3, dv.byteLength - 128) == 'TAG') {
//         var title = dv.getString(30, dv.tell());
//         var artist = dv.getString(30, dv.tell());
//         var album = dv.getString(30, dv.tell());
//         var year = dv.getString(4, dv.tell());
//         console.log(`${title} ${artist} ${album} ${year}`)
//     } else {
//         // no ID3v1 data found.
//     }
// };
// const response = await fetch(trackUri);
// const blob = await response.blob();
// reader.readAsArrayBuffer(blob);


function factory(global: any) {
    function is(obj: any, Ctor: any) {
        return "object" != typeof obj || null === obj ? !1 : obj.constructor === Ctor || Object.prototype.toString.call(obj) === "[object " + Ctor.name + "]";
    }
    function arrayFrom(arrayLike: any, forceCopy: any) {
        return !forceCopy && is(arrayLike, Array) ? arrayLike : Array.prototype.slice.call(arrayLike);
    }
    function defined(value: any, defaultValue: any) {
        return void 0 !== value ? value : defaultValue;
    }
    /*export*/ function jDataView(buffer: any, byteOffset: number, byteLength: number, littleEndian: boolean) {
        if (jDataView.is(buffer)) {
            var result = buffer.slice(byteOffset, byteOffset + byteLength);
            return result._littleEndian = defined(littleEndian, result._littleEndian), result;
        }
        if (!jDataView.is(this)) return new jDataView(buffer, byteOffset, byteLength, littleEndian);
        if (this.buffer = buffer = jDataView.wrapBuffer(buffer), this._isArrayBuffer = compatibility.ArrayBuffer && is(buffer, ArrayBuffer),
            this._isPixelData = !1, this._isDataView = compatibility.DataView && this._isArrayBuffer,
            this._isNodeBuffer = !0 && compatibility.NodeBuffer && is(buffer, Buffer), !this._isNodeBuffer && !this._isArrayBuffer && !is(buffer, Array)) throw new TypeError("jDataView buffer has an incompatible type");
        this._littleEndian = !!littleEndian;
        var bufferLength = "byteLength" in buffer ? buffer.byteLength : buffer.length;
        this.byteOffset = byteOffset = defined(byteOffset, 0), this.byteLength = byteLength = defined(byteLength, bufferLength - byteOffset),
            this._offset = this._bitOffset = 0, this._isDataView ? this._view = new DataView(buffer, byteOffset, byteLength) : this._checkBounds(byteOffset, byteLength, bufferLength),
            this._engineAction = this._isDataView ? this._dataViewAction : this._isNodeBuffer ? this._nodeBufferAction : this._isArrayBuffer ? this._arrayBufferAction : this._arrayAction;
    }
    function getCharCodes(string) {
        if (compatibility.NodeBuffer) return new Buffer(string, "binary");
        for (var Type = compatibility.ArrayBuffer ? Uint8Array : Array, codes = new Type(string.length), i = 0, length = string.length; length > i; i++) codes[i] = 255 & string.charCodeAt(i);
        return codes;
    }
    function pow2(n) {
        return n >= 0 && 31 > n ? 1 << n : pow2[n] || (pow2[n] = Math.pow(2, n));
    }
    function Uint64(lo, hi) {
        this.lo = lo, this.hi = hi;
    }
    function Int64() {
        Uint64.apply(this, arguments);
    }
    var compatibility = {
        NodeBuffer: !0 && "Buffer" in global,
        DataView: "DataView" in global,
        ArrayBuffer: "ArrayBuffer" in global,
        PixelData: !1
    }, TextEncoder = global.TextEncoder, TextDecoder = global.TextDecoder;
    compatibility.NodeBuffer && !function (buffer) {
        try {
            buffer.writeFloatLE(1 / 0, 0);
        } catch (e) {
            compatibility.NodeBuffer = !1;
        }
    }(Buffer.alloc(4));
    var dataTypes = {
        Int8: 1,
        Int16: 2,
        Int32: 4,
        Uint8: 1,
        Uint16: 2,
        Uint32: 4,
        Float32: 4,
        Float64: 8
    };
    jDataView.wrapBuffer = function (buffer) {
        switch (typeof buffer) {
            case "number":
                if (compatibility.NodeBuffer) buffer = new Buffer(buffer), buffer.fill(0); else if (compatibility.ArrayBuffer) buffer = new Uint8Array(buffer).buffer; else {
                    buffer = new Array(buffer);
                    for (var i = 0; i < buffer.length; i++) buffer[i] = 0;
                }
                return buffer;

            case "string":
                buffer = getCharCodes(buffer);

            default:
                return "length" in buffer && !(compatibility.NodeBuffer && is(buffer, Buffer) || compatibility.ArrayBuffer && is(buffer, ArrayBuffer)) && (compatibility.NodeBuffer ? buffer = new Buffer(buffer) : compatibility.ArrayBuffer ? is(buffer, ArrayBuffer) || (buffer = new Uint8Array(buffer).buffer,
                    is(buffer, ArrayBuffer) || (buffer = new Uint8Array(arrayFrom(buffer, !0)).buffer)) : buffer = arrayFrom(buffer)),
                    buffer;
        }
    }, jDataView.is = function (view) {
        return view && view.jDataView;
    }, jDataView.from = function () {
        return new jDataView(arguments);
    }, jDataView.Uint64 = Uint64, Uint64.prototype = {
        valueOf: function () {
            return this.lo + pow2(32) * this.hi;
        },
        toString: function () {
            return Number.prototype.toString.apply(this.valueOf(), arguments);
        }
    }, Uint64.fromNumber = function (number) {
        var hi = Math.floor(number / pow2(32)), lo = number - hi * pow2(32);
        return new Uint64(lo, hi);
    }, jDataView.Int64 = Int64, Int64.prototype = "create" in Object ? Object.create(Uint64.prototype) : new Uint64(),
        Int64.prototype.valueOf = function () {
            return this.hi < pow2(31) ? Uint64.prototype.valueOf.apply(this, arguments) : -(pow2(32) - this.lo + pow2(32) * (pow2(32) - 1 - this.hi));
        }, Int64.fromNumber = function (number) {
            var lo, hi;
            if (number >= 0) {
                var unsigned = Uint64.fromNumber(number);
                lo = unsigned.lo, hi = unsigned.hi;
            } else hi = Math.floor(number / pow2(32)), lo = number - hi * pow2(32), hi += pow2(32);
            return new Int64(lo, hi);
        };
    var proto = jDataView.prototype = {
        compatibility: compatibility,
        jDataView: !0,
        _checkBounds: function (byteOffset, byteLength, maxLength) {
            if ("number" != typeof byteOffset) throw new TypeError("Offset is not a number.");
            if ("number" != typeof byteLength) throw new TypeError("Size is not a number.");
            if (0 > byteLength) throw new RangeError("Length is negative.");
            if (0 > byteOffset || byteOffset + byteLength > defined(maxLength, this.byteLength)) throw new RangeError("Offsets are out of bounds.");
        },
        _action: function (type, isReadAction, byteOffset, littleEndian, value) {
            return this._engineAction(type, isReadAction, defined(byteOffset, this._offset), defined(littleEndian, this._littleEndian), value);
        },
        _dataViewAction: function (type, isReadAction, byteOffset, littleEndian, value) {
            return this._offset = byteOffset + dataTypes[type], isReadAction ? this._view["get" + type](byteOffset, littleEndian) : this._view["set" + type](byteOffset, value, littleEndian);
        },
        _arrayBufferAction: function (type, isReadAction, byteOffset, littleEndian, value) {
            var typedArray, size = dataTypes[type], TypedArray = global[type + "Array"];
            if (littleEndian = defined(littleEndian, this._littleEndian), 1 === size || (this.byteOffset + byteOffset) % size === 0 && littleEndian) return typedArray = new TypedArray(this.buffer, this.byteOffset + byteOffset, 1),
                this._offset = byteOffset + size, isReadAction ? typedArray[0] : typedArray[0] = value;
            var bytes = new Uint8Array(isReadAction ? this.getBytes(size, byteOffset, littleEndian, !0) : size);
            return typedArray = new TypedArray(bytes.buffer, 0, 1), isReadAction ? typedArray[0] : (typedArray[0] = value,
                void this._setBytes(byteOffset, bytes, littleEndian));
        },
        _arrayAction: function (type, isReadAction, byteOffset, littleEndian, value) {
            return isReadAction ? this["_get" + type](byteOffset, littleEndian) : this["_set" + type](byteOffset, value, littleEndian);
        },
        _getBytes: function (length, byteOffset, littleEndian) {
            littleEndian = defined(littleEndian, this._littleEndian), byteOffset = defined(byteOffset, this._offset),
                length = defined(length, this.byteLength - byteOffset), this._checkBounds(byteOffset, length),
                byteOffset += this.byteOffset, this._offset = byteOffset - this.byteOffset + length;
            var result = this._isArrayBuffer ? new Uint8Array(this.buffer, byteOffset, length) : (this.buffer.slice || Array.prototype.slice).call(this.buffer, byteOffset, byteOffset + length);
            return littleEndian || 1 >= length ? result : arrayFrom(result).reverse();
        },
        getBytes: function (length, byteOffset, littleEndian, toArray) {
            var result = this._getBytes(length, byteOffset, defined(littleEndian, !0));
            return toArray ? arrayFrom(result) : result;
        },
        _setBytes: function (byteOffset, bytes, littleEndian) {
            var length = bytes.length;
            if (0 !== length) {
                if (littleEndian = defined(littleEndian, this._littleEndian), byteOffset = defined(byteOffset, this._offset),
                    this._checkBounds(byteOffset, length), !littleEndian && length > 1 && (bytes = arrayFrom(bytes, !0).reverse()),
                    byteOffset += this.byteOffset, this._isArrayBuffer) new Uint8Array(this.buffer, byteOffset, length).set(bytes); else if (this._isNodeBuffer) new Buffer(bytes).copy(this.buffer, byteOffset); else for (var i = 0; length > i; i++) this.buffer[byteOffset + i] = bytes[i];
                this._offset = byteOffset - this.byteOffset + length;
            }
        },
        setBytes: function (byteOffset, bytes, littleEndian) {
            this._setBytes(byteOffset, bytes, defined(littleEndian, !0));
        },
        getString: function (byteLength, byteOffset, encoding) {
            if (this._isNodeBuffer) return byteOffset = defined(byteOffset, this._offset), byteLength = defined(byteLength, this.byteLength - byteOffset),
                this._checkBounds(byteOffset, byteLength), this._offset = byteOffset + byteLength,
                this.buffer.toString(encoding || "binary", this.byteOffset + byteOffset, this.byteOffset + this._offset);
            var bytes = this._getBytes(byteLength, byteOffset, !0);
            if (encoding = "utf8" === encoding ? "utf-8" : encoding || "binary", TextDecoder && "binary" !== encoding) return new TextDecoder(encoding).decode(this._isArrayBuffer ? bytes : new Uint8Array(bytes));
            var string = "";
            byteLength = bytes.length;
            for (var i = 0; byteLength > i; i++) string += String.fromCharCode(bytes[i]);
            return "utf-8" === encoding && (string = decodeURIComponent(escape(string))), string;
        },
        setString: function (byteOffset, subString, encoding) {
            if (this._isNodeBuffer) return byteOffset = defined(byteOffset, this._offset), this._checkBounds(byteOffset, subString.length),
                void (this._offset = byteOffset + this.buffer.write(subString, this.byteOffset + byteOffset, encoding || "binary"));
            encoding = "utf8" === encoding ? "utf-8" : encoding || "binary";
            var bytes;
            TextEncoder && "binary" !== encoding ? bytes = new TextEncoder(encoding).encode(subString) : ("utf-8" === encoding && (subString = unescape(encodeURIComponent(subString))),
                bytes = getCharCodes(subString)), this._setBytes(byteOffset, bytes, !0);
        },
        getChar: function (byteOffset) {
            return this.getString(1, byteOffset);
        },
        setChar: function (byteOffset, character) {
            this.setString(byteOffset, character);
        },
        tell: function () {
            return this._offset;
        },
        seek: function (byteOffset) {
            return this._checkBounds(byteOffset, 0), this._offset = byteOffset;
        },
        skip: function (byteLength) {
            return this.seek(this._offset + byteLength);
        },
        slice: function (start, end, forceCopy) {
            function normalizeOffset(offset, byteLength) {
                return 0 > offset ? offset + byteLength : offset;
            }
            return start = normalizeOffset(start, this.byteLength), end = normalizeOffset(defined(end, this.byteLength), this.byteLength),
                forceCopy ? new jDataView(this.getBytes(end - start, start, !0, !0), void 0, void 0, this._littleEndian) : new jDataView(this.buffer, this.byteOffset + start, end - start, this._littleEndian);
        },
        alignBy: function (byteCount) {
            return this._bitOffset = 0, 1 !== defined(byteCount, 1) ? this.skip(byteCount - (this._offset % byteCount || byteCount)) : this._offset;
        },
        _getFloat64: function (byteOffset, littleEndian) {
            var b = this._getBytes(8, byteOffset, littleEndian), sign = 1 - 2 * (b[7] >> 7), exponent = ((b[7] << 1 & 255) << 3 | b[6] >> 4) - 1023, mantissa = (15 & b[6]) * pow2(48) + b[5] * pow2(40) + b[4] * pow2(32) + b[3] * pow2(24) + b[2] * pow2(16) + b[1] * pow2(8) + b[0];
            return 1024 === exponent ? 0 !== mantissa ? 0 / 0 : 1 / 0 * sign : -1023 === exponent ? sign * mantissa * pow2(-1074) : sign * (1 + mantissa * pow2(-52)) * pow2(exponent);
        },
        _getFloat32: function (byteOffset, littleEndian) {
            var b = this._getBytes(4, byteOffset, littleEndian), sign = 1 - 2 * (b[3] >> 7), exponent = (b[3] << 1 & 255 | b[2] >> 7) - 127, mantissa = (127 & b[2]) << 16 | b[1] << 8 | b[0];
            return 128 === exponent ? 0 !== mantissa ? 0 / 0 : 1 / 0 * sign : -127 === exponent ? sign * mantissa * pow2(-149) : sign * (1 + mantissa * pow2(-23)) * pow2(exponent);
        },
        _get64: function (Type, byteOffset, littleEndian) {
            littleEndian = defined(littleEndian, this._littleEndian), byteOffset = defined(byteOffset, this._offset);
            for (var parts = littleEndian ? [0, 4] : [4, 0], i = 0; 2 > i; i++) parts[i] = this.getUint32(byteOffset + parts[i], littleEndian);
            return this._offset = byteOffset + 8, new Type(parts[0], parts[1]);
        },
        getInt64: function (byteOffset, littleEndian) {
            return this._get64(Int64, byteOffset, littleEndian);
        },
        getUint64: function (byteOffset, littleEndian) {
            return this._get64(Uint64, byteOffset, littleEndian);
        },
        _getInt32: function (byteOffset, littleEndian) {
            var b = this._getBytes(4, byteOffset, littleEndian);
            return b[3] << 24 | b[2] << 16 | b[1] << 8 | b[0];
        },
        _getUint32: function (byteOffset, littleEndian) {
            return this._getInt32(byteOffset, littleEndian) >>> 0;
        },
        _getInt16: function (byteOffset, littleEndian) {
            return this._getUint16(byteOffset, littleEndian) << 16 >> 16;
        },
        _getUint16: function (byteOffset, littleEndian) {
            var b = this._getBytes(2, byteOffset, littleEndian);
            return b[1] << 8 | b[0];
        },
        _getInt8: function (byteOffset) {
            return this._getUint8(byteOffset) << 24 >> 24;
        },
        _getUint8: function (byteOffset) {
            return this._getBytes(1, byteOffset)[0];
        },
        _getBitRangeData: function (bitLength, byteOffset) {
            var startBit = (defined(byteOffset, this._offset) << 3) + this._bitOffset, endBit = startBit + bitLength, start = startBit >>> 3, end = endBit + 7 >>> 3, b = this._getBytes(end - start, start, !0), wideValue = 0;
            (this._bitOffset = 7 & endBit) && (this._bitOffset -= 8);
            for (var i = 0, length = b.length; length > i; i++) wideValue = wideValue << 8 | b[i];
            return {
                start: start,
                bytes: b,
                wideValue: wideValue
            };
        },
        getSigned: function (bitLength, byteOffset) {
            var shift = 32 - bitLength;
            return this.getUnsigned(bitLength, byteOffset) << shift >> shift;
        },
        getUnsigned: function (bitLength, byteOffset) {
            var value = this._getBitRangeData(bitLength, byteOffset).wideValue >>> -this._bitOffset;
            return 32 > bitLength ? value & ~(-1 << bitLength) : value;
        },
        _setBinaryFloat: function (byteOffset, value, mantSize, expSize, littleEndian) {
            var exponent, mantissa, signBit = 0 > value ? 1 : 0, eMax = ~(-1 << expSize - 1), eMin = 1 - eMax;
            0 > value && (value = -value), 0 === value ? (exponent = 0, mantissa = 0) : isNaN(value) ? (exponent = 2 * eMax + 1,
                mantissa = 1) : 1 / 0 === value ? (exponent = 2 * eMax + 1, mantissa = 0) : (exponent = Math.floor(Math.log(value) / Math.LN2),
                    exponent >= eMin && eMax >= exponent ? (mantissa = Math.floor((value * pow2(-exponent) - 1) * pow2(mantSize)),
                        exponent += eMax) : (mantissa = Math.floor(value / pow2(eMin - mantSize)), exponent = 0));
            for (var b = []; mantSize >= 8;) b.push(mantissa % 256), mantissa = Math.floor(mantissa / 256),
                mantSize -= 8;
            for (exponent = exponent << mantSize | mantissa, expSize += mantSize; expSize >= 8;) b.push(255 & exponent),
                exponent >>>= 8, expSize -= 8;
            b.push(signBit << expSize | exponent), this._setBytes(byteOffset, b, littleEndian);
        },
        _setFloat32: function (byteOffset, value, littleEndian) {
            this._setBinaryFloat(byteOffset, value, 23, 8, littleEndian);
        },
        _setFloat64: function (byteOffset, value, littleEndian) {
            this._setBinaryFloat(byteOffset, value, 52, 11, littleEndian);
        },
        _set64: function (Type, byteOffset, value, littleEndian) {
            "object" != typeof value && (value = Type.fromNumber(value)), littleEndian = defined(littleEndian, this._littleEndian),
                byteOffset = defined(byteOffset, this._offset);
            var parts = littleEndian ? {
                lo: 0,
                hi: 4
            } : {
                lo: 4,
                hi: 0
            };
            for (var partName in parts) this.setUint32(byteOffset + parts[partName], value[partName], littleEndian);
            this._offset = byteOffset + 8;
        },
        setInt64: function (byteOffset, value, littleEndian) {
            this._set64(Int64, byteOffset, value, littleEndian);
        },
        setUint64: function (byteOffset, value, littleEndian) {
            this._set64(Uint64, byteOffset, value, littleEndian);
        },
        _setUint32: function (byteOffset, value, littleEndian) {
            this._setBytes(byteOffset, [255 & value, value >>> 8 & 255, value >>> 16 & 255, value >>> 24], littleEndian);
        },
        _setUint16: function (byteOffset, value, littleEndian) {
            this._setBytes(byteOffset, [255 & value, value >>> 8 & 255], littleEndian);
        },
        _setUint8: function (byteOffset, value) {
            this._setBytes(byteOffset, [255 & value]);
        },
        setUnsigned: function (byteOffset, value, bitLength) {
            var data = this._getBitRangeData(bitLength, byteOffset), wideValue = data.wideValue, b = data.bytes;
            wideValue &= ~(~(-1 << bitLength) << -this._bitOffset), wideValue |= (32 > bitLength ? value & ~(-1 << bitLength) : value) << -this._bitOffset;
            for (var i = b.length - 1; i >= 0; i--) b[i] = 255 & wideValue, wideValue >>>= 8;
            this._setBytes(data.start, b, !0);
        }
    }, nodeNaming = {
        Int8: "Int8",
        Int16: "Int16",
        Int32: "Int32",
        Uint8: "UInt8",
        Uint16: "UInt16",
        Uint32: "UInt32",
        Float32: "Float",
        Float64: "Double"
    };
    proto._nodeBufferAction = function (type, isReadAction, byteOffset, littleEndian, value) {
        this._offset = byteOffset + dataTypes[type];
        var nodeName = nodeNaming[type] + ("Int8" === type || "Uint8" === type ? "" : littleEndian ? "LE" : "BE");
        return byteOffset += this.byteOffset, isReadAction ? this.buffer["read" + nodeName](byteOffset) : this.buffer["write" + nodeName](value, byteOffset);
    };
    for (var type in dataTypes) !function (type) {
        proto["get" + type] = function (byteOffset, littleEndian) {
            return this._action(type, !0, byteOffset, littleEndian);
        }, proto["set" + type] = function (byteOffset, value, littleEndian) {
            this._action(type, !1, byteOffset, littleEndian, value);
        };
    }(type);
    proto._setInt32 = proto._setUint32, proto._setInt16 = proto._setUint16, proto._setInt8 = proto._setUint8,
        proto.setSigned = proto.setUnsigned;
    for (var method in proto) "set" === method.slice(0, 3) && !function (type) {
        proto["write" + type] = function () {
            Array.prototype.unshift.call(arguments, void 0), this["set" + type].apply(this, arguments);
        };
    }(method.slice(3));

    return jDataView;
};

const global = this ?? globalThis;
export const jDataView = factory(global);