import { makeAutoObservable } from 'mobx';
import { jDataView } from '../utilities/jDataView'
import picturePlaceholder from '../static/images/picture-placeholder.svg'

const audioElement = new Audio();

class PlaybackStore {
    trackUri: string | null = null;
    artist: string | null = "unknown artist";
    album: string | null = "unknown album";
    title: string | null = null;
    year: string | null = null;
    coverArtUri: string = picturePlaceholder;

    state: 'playing' | 'paused' | 'stopped' = 'paused';

    /**
     * Percent of maximum volume. valid values are in the range [0, 1]
     */
    volume: number = 0.2;

    offset: number = 0;
    // todo: could use offset and update it once per second or
    // could store a timestamp of when the track started playback
    // which would only be updated when the track is played/paused
    // or when the track advances. It would then be UI component
    // responsibility to calculate the offset to display the
    // elapsed/remaining time or move a position scrubber.

    // @observable
    // public playQueue: Array<Track> = [];

    constructor() {
        makeAutoObservable(this);
        audioElement.volume = this.volume;
    }

    public async setTrackUri(trackUri: string | null) {
        if (trackUri === '') {
            trackUri = null;
        }

        // audioElement.pause();
        audioElement.src = trackUri === null ? '' : trackUri;
        this.trackUri = trackUri;
        this.clearTrackInfo();

        if (trackUri == null) {
            this.stop();
            return;
        }

        // todo: read id3 tags to get track information
        // artist, album, track, cover art, etc.

        // todo: store reference to reader for active load,
        // cancel load if new track uri is set before load completes.
        var reader = new FileReader();

        reader.onload = (_e: ProgressEvent<FileReader>) => {
            // @ts-ignore
            var dv = new jDataView(reader.result);
            //_e.target?.result

            // "TAG" starts at byte -128 from EOF.
            // See http://en.wikipedia.org/wiki/ID3
            if (dv.getString(3, dv.byteLength - 128) == 'TAG') {
                var title = dv.getString(30, dv.tell());
                var artist = dv.getString(30, dv.tell());
                var album = dv.getString(30, dv.tell());
                var year = dv.getString(4, dv.tell());

                this.setTrackInfo(artist, album, title, year, picturePlaceholder /* coverArtUri */);
            } else {
                // no ID3v1 data found.
                this.clearTrackInfo();
            }
        };

        const response = await fetch(trackUri);
        const blob = await response.blob();
        reader.readAsArrayBuffer(blob);
    }

    private setTrackInfo(artist: string, album: string, title: string, year: string, coverArtUri: string) {
        this.artist = artist;
        this.album = album;
        this.title = title;
        this.year = year;
        this.coverArtUri = coverArtUri;
    }

    private clearTrackInfo() {
        this.artist = "unknown artist";
        this.album = "unknown album";
        this.title = "unknown title";
        this.year = "unknown year";
    }

    public togglePlayPauseState() {
        if (this.state === 'playing') {
            this.pause();
        } else {
            this.play();
        }
    }

    public play() {
        if (this.trackUri !== null) {
            audioElement.play();
            this.state = 'playing';
        }
    }

    public pause() {
        if (this.trackUri !== null) {
            audioElement.pause();
            this.state = 'paused';
        }
    }

    public stop() {
        this.setTrackUri(null);
        this.state = 'stopped'
    }

    public volumeUp() {
        audioElement.volume = Math.min(1.0, audioElement.volume + 0.1);
    }

    public volumeDown() {
        audioElement.volume = Math.max(0.0, audioElement.volume - 0.1);
    }

    /**
     *
     * @param volumeLevel number in range [0, 1] with 0 representing 0% and 1 being 100%.
     */
    public setVolume(volumeLevel: number) {
        // round to nearest hundredth
        const roundedVolumeLevel = parseFloat(volumeLevel.toFixed(2));

        // clam to [0.0, 1.0]
        const clampedVolumeLevel = Math.min(1.0, Math.max(0.0, roundedVolumeLevel));

        this.volume = clampedVolumeLevel;
        audioElement.volume = clampedVolumeLevel;
    }
}

export const playbackStore = new PlaybackStore();
