import { playbackStore } from "../stores/playbackStore";

// This PWA registers as a handler for { "audio/mpeg", ".mp3" }
if ("launchQueue" in window) {
    const launchQueue: any = window.launchQueue;
    launchQueue.setConsumer(async (launchParams: any) => {
        for (const file of launchParams.files) {

            const src = URL.createObjectURL(await file.getFile());
            playbackStore.setTrackUri(src);
            playbackStore.play();

            break; // todo: handle multiple files by adding to playback queue, or library
        }
    });
}
