import { MobxLitElement } from '@adobe/lit-mobx';
import { css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';
import { playbackStore } from '../stores/playbackStore';
import { computed } from 'mobx';
import { assertNever } from '../utilities/assertNever';

@customElement('play-pause-button')
export class PlayPauseButton extends MobxLitElement {
  @property({ type: String }) state = playbackStore.state;

  static styles = css`
    :host {
        --duration: .1s;
        --size: 100%;
        --leftBarSlideX: -6px;
    }

    @media(prefers-color-scheme: light) {
      :host {
        --bg-color: #ffffff;
        --fg-color: #101010;
      }
    }

    @media(prefers-color-scheme: dark) {
      :host {
        --bg-color: '#101010';
        --fg-color: '#ffffff';
      }
    }

    #root {
        position: relative;
        width: var(--size);
        height: var(--size);
        border-radius: 50%;
        overflow: hidden;
        background: var(--bg-color);
        overflow: hidden;
        transition: transform var(--duration) linear, opacity var(--duration) linear;
        transform-origin: center;

      &:hover {
        transform: scale(1.1, 1.1);
        opacity: 0.9;
      }
    }

    #container {
        width: 100%;
        height: 100%;
        transition: transform var(--duration) linear;
        overflow: hidden;
        margin-left: 1px;

        --container-scale: 0.75;
        transform-origin: center;
    }

    #container.paused {
      transform: scale(var(--container-scale), var(--container-scale)) translate(3px, 0);
    }
    #container.playing {
      transform: scale(var(--container-scale), var(--container-scale)) translate(-1px, 0);
    }

    #leftBarTop {
      position: absolute;
      bottom: 50%;
      left: 25%;
      width: 15%;
      height: 40%;
      background: var(--fg-color);
      transition: transform var(--duration) linear;
      animation-fill-mode: forwards;
      transform-origin: bottom right;
    }
    #leftBarTop.playing {
      transform: translate(0, 0) rotate(0);
    }
    #leftBarTop.paused {
      transform: translate(var(--leftBarSlideX), 0) rotate(-60deg);
    }

    #leftBarTopClip {
      position: absolute;
      bottom: 50%;
      left: 40%;
      width: 30%;
      height: 30%;
      background: var(--bg-color);
      transition: transform var(--duration) linear;
      animation-fill-mode: forwards;
      transform-origin: bottom left;
    }
    #leftBarTopClip.playing {
      transform: translate(0, 0) rotate(0);
    }
    #leftBarTopClip.paused {
      transform: translate(var(--leftBarSlideX), 0) rotate(-60deg);
    }

    #leftBarBottom {
      position: absolute;
      top: 50%;
      left: 25%;
      width: 15%;
      height: 40%;
      background: var(--fg-color);
      transition: transform var(--duration) linear;
      animation-fill-mode: forwards;
      transform-origin: top right;
    }
    #leftBarBottom.playing {
      transform: translate(0, 0) rotate(0);
    }
    #leftBarBottom.paused {
      transform: translate(var(--leftBarSlideX), 0) rotate(60deg);
    }

    #leftBarBottomClip {
      position: absolute;
      top: 50%;
      left: 40%;
      width: 30%;
      height: 30%;
      background: var(--bg-color);
      transition: transform var(--duration) linear;
      animation-fill-mode: forwards;
      transform-origin: top left;
    }
    #leftBarBottomClip.playing {
      transform: translate(0, 0) rotate(0);
    }
    #leftBarBottomClip.paused {
      transform: translate(var(--leftBarSlideX), 0) rotate(60deg);
    }

    #rightBarTop {
      position: absolute;
      bottom: 50%;
      right: 25%;
      width: 15%;
      height: 70%;
      background: var(--fg-color);
      transition: transform var(--duration) linear;
      animation-fill-mode: forwards;
      transform-origin: bottom right;
    }
    #rightBarTop.playing {
      transform: translate(0, 0) rotate(0deg);
    }
    #rightBarTop.paused {
      transform: translate(0, 0) rotate(-60deg);
    }

    #rightBarTopGhost {
      position: absolute;
      bottom: 50%;
      right: 25%;
      width: 15%;
      height: 70%;
      background: var(--fg-color);
      transition: transform var(--duration) linear;
      animation-fill-mode: forwards;
      transform-origin: bottom right;
    }
    #rightBarTopGhost.playing {
      transform: rotate(0) translate(0, 0);
    }
    #rightBarTopGhost.paused {
      transform: rotate(-75deg) translate(0, 0%);
    }

    #rightBarTopClip {
      position: absolute;
      bottom: 50%;
      right: 10%;
      width: 15%;
      height: 100%;
      background: var(--bg-color);
      transition: transform var(--duration) linear;
      animation-fill-mode: forwards;
      transform-origin: bottom left;
    }
    #rightBarTopClip.playing {
      transform: translate(0, 0) rotate(0);
    }
    #rightBarTopClip.paused {
      transform: translate(0, 0) rotate(-60deg);
    }

    #rightBarBottom {
      position: absolute;
      top: 50%;
      right: 25%;
      width: 15%;
      height: 70%;
      background: var(--fg-color);
      transition: transform var(--duration) linear;
      animation-fill-mode: forwards;
      transform-origin: top right;
    }
    #rightBarBottom.playing {
      transform: translate(0, 0) rotate(0);
    }
    #rightBarBottom.paused {
      transform: translate(0, 0) rotate(60deg);
    }

    #rightBarBottomGhost {
      position: absolute;
      top: 50%;
      right: 25%;
      width: 15%;
      height: 70%;
      background: var(--fg-color);
      transition: transform var(--duration) linear;
      animation-fill-mode: forwards;
      transform-origin: top right;
    }
    #rightBarBottomGhost.playing {
      transform: translate(0, 0) rotate(0);
    }
    #rightBarBottomGhost.paused {
      transform: translate(0, 0) rotate(75deg);
    }

    #rightBarBottomClip {
      position: absolute;
      top: 50%;
      right: 10%;
      width: 15%;
      height: 100%;
      background: var(--bg-color);
      transition: transform var(--duration) linear;
      animation-fill-mode: forwards;
      transform-origin: top left;
    }
    #rightBarBottomClip.playing {
      transform: translate(0, 0) rotate(0);
    }
    #rightBarBottomClip.paused {
      transform: translate(0, 0) rotate(60deg);
    }

    #leftClip {
        position: absolute;
        top: 0%;
        height: 100%;
        left: -10%;
        width: 30%;
        background: var(--bg-color);
    }

    #topClip {
        position: absolute;
        top: 0%;
        height: 18%;
        left: 0%;
        width: 100%;
        background: var(--bg-color);
    }

    #bottomClip {
        position: absolute;
        bottom: 0%;
        height: 18%;
        left: 0%;
        width: 100%;
        background: var(--bg-color);
    }
  `;

  render() {
    if (this.state === 'stopped') {
      // todo: implement disabled state
      return html`<div id="root"></div>`;
    }

    const playPauseState = this.getPlayPauseState();
    return html`
      <div id="root" @click="${this.toggle}">
        <div id="container" class="${playPauseState}">
            <div id="leftBarTop" class="${playPauseState}"></div>
            <div id="leftBarBottom" class="${playPauseState}"></div>

            <div id="rightBarTop" class="${playPauseState}"></div>
            <div id="rightBarTopGhost" class="${playPauseState}"></div>
            <div id="rightBarBottom" class="${playPauseState}"></div>
            <div id="rightBarBottomGhost" class="${playPauseState}"></div>

            <div id="rightBarTopClip" class="${playPauseState}"></div>
            <div id="rightBarBottomClip" class="${playPauseState}"></div>

            <div id="topClip"></div>
            <div id="bottomClip"></div>
            <div id="leftClip"></div>
        </div>
      </div>
    `;
  }

  @computed
  private getPlayPauseState() {
    switch (playbackStore.state) {
      case 'paused':
      case 'stopped':
        return 'paused';

      case 'playing':
        return 'playing';

      default:
        assertNever(playbackStore.state);
    }
  }

  private toggle(_e: Event) {
    playbackStore.togglePlayPauseState();
  }
}
